import React from "react"
import PropTypes from "prop-types"
import styles from "./DefinitionList.module.css"
import cx from "classnames"
import Text from "../Text/Text"

export default function DefinitionList({ items, wrapped }) {
  const itemContainerClass = cx(styles.itemContainer, {
    [styles.wrapped]: wrapped,
  })

  return (
    <dl className={styles.list}>
      {items.map(({ label, value }, i) => (
        <div key={i} className={itemContainerClass}>
          <dt className={styles.itemLabel}>
            <Text spread inline uppercase bold>
              {label}
            </Text>
          </dt>
          <dd className={styles.itemValue}>
            <Text spread inline uppercase>
              {value}
            </Text>
          </dd>
        </div>
      ))}
    </dl>
  )
}

DefinitionList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
}
