import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts/Layout/Layout"
import Carousel from "../components/Carousel/Carousel"
import Section from "../components/Section/Section"
import Title from "../components/Title/Title"
import DefinitionList from "../components/DefinitionList/DefinitionList"
import Spacer from "../components/Spacer/Spacer"
import Text from "../components/Text/Text"
import Columns from "../components/Columns/Columns"

export default function AboutPage({ data }) {
  const { about } = data
  const collaborators = about.collaborators.reduce(
    (acc, collab) => {
      if (collab.active) {
        acc.active.push(collab.name)
      } else {
        acc.inactive.push(collab.name)
      }

      return acc
    },
    { active: [], inactive: [] }
  )

  return (
    <Layout seoTitle="Escritório" footerDivider>
      <Carousel items={(about.carousel || []).map(o => ({ image: o }))} />
      <Section>
        <Title>{about.title}</Title>
        <Spacer />
        <DefinitionList
          items={[
            { label: "Endereço", value: about.address },
            { label: "E-mail", value: about.email },
            { label: "Telefone", value: about.telephone },
          ]}
        />
      </Section>
      <Section>
        <Title />
        <Columns
          items={about.about.map(({ content }, i) => (
            <Text>{content}</Text>
          ))}
        />
        <Spacer />
        <Spacer />
        <Text bold uppercase>
          Sócios
        </Text>
        <Spacer />
        <Columns
          items={about.partners.map(({ name, bio }) => (
            <>
              <Text bold>{name}</Text>
              <Text>{bio}</Text>
            </>
          ))}
        />
        <Spacer />
        <Spacer />
        <Columns
          items={[
            <>
              <Text bold uppercase>
                Colaboradores
              </Text>
              <Spacer />
              <Text>{collaborators.active.join(", ")}</Text>
              <Spacer />
              <Text bold uppercase>
                Antigos Colaboradores
              </Text>
              <Spacer />
              <Text>{collaborators.inactive.join(", ")}</Text>
            </>,
          ]}
        />
        <Spacer />
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    about: datoCmsAbout {
      title
      address
      email
      telephone
      about {
        ... on DatoCmsColumn {
          content
        }
      }
      partners {
        ... on DatoCmsPartnersItem {
          name
          bio
        }
      }
      collaborators {
        ... on DatoCmsCollaboratorsItem {
          name
          active
        }
      }
      carousel {
        fluid(maxWidth: 960, imgixParams: { fm: "webp", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`
