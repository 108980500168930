import React from "react"
import styles from "./Columns.module.css"

export default function Columns({ items }) {
  return (
    <div className={styles.columns}>
      {items.map((item, i) => (
        <div key={i} className={styles.columnsItem}>
          {item}
        </div>
      ))}
    </div>
  )
}
